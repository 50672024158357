<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="purchases" v-model:filters="filters1" v-model:expandedRows="expandedRows"
                    v-model:selection="selectedRow" selectionMode="multiple" :metaKeySelection="false"
                    :filters="filters1" filterDisplay="menu" dataKey="id"
                    :globalFilterFields="['purchaseno','factoryname']" scrollable :tableStyle="styleHeight"
                    :scrollHeight="sHeight" responsiveLayout="scroll" :loading="loading" :paginator="true" :lazy="true"
                    :rows="pagesize" :virtualScrollerOptions="vsOptionFun()" :rowsPerPageOptions="[50, 100, 200, 500]"
                    :totalRecords="totalRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listPage($event)" @sort="onSort($event)">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                                @click="clearFilter1()" />
                            <span class="p-input-icon-left mb-2" style="width:30%">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="输入采购合同号或工厂简称模糊查询"
                                    style="width:100%" @keyup.enter="customFilter();" />
                            </span>
                        </div>
                    </template>
                    <Column frozen bodyStyle="width:45px;" headerStyle="width:45px;">
                        <template #body="slotProps">
                            <button @click="expandertoggle($event,slotProps.data)" aria-haspopup="true"
                                :aria-controls="slotProps.data['id']" class="p-row-toggler p-link" type="button"><span
                                    class="p-row-toggler-icon pi pi-chevron-right"></span><span
                                    class="p-ink"></span></button>
                            <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" :id="slotProps.data['id']"
                                style="width:650px" :breakpoints="{'960px': '75vw'}">
                                <TabView>
                                    <TabPanel header="采购产品">
                                        <DataTable :value="expandedata['products']"
                                            v-model:selection="selectedProductRow" dataKey="id"
                                            :metaKeySelection="false" scrollHeight="500px" selectionMode="multiple"
                                            scrollDirection="both" :loading="childloading" responsiveLayout="scroll">
                                            <Column selectionMode="multiple" frozen headerStyle="width: 3em"></Column>
                                            <Column field="mpn" style="min-width:200px" frozen header="平台MPN"></Column>
                                            <Column field="sku" style="min-width:200px" frozen header="工厂SKU"></Column>
                                            <Column field="productname" style="min-width:200px" frozen header="产品名称">
                                                <template #body="slotProps">
                                                    <label class="oneline"
                                                        v-tooltip.top="{ value: slotProps.data.productname, disabled: false }">{{slotProps.data.productname}}</label>
                                                </template>
                                            </Column>
                                            <Column style="min-width:100px" header="图片">
                                                <template #body="slotProps">
                                                    <Image :src="getFilePatch(slotProps.data.url)" width="50" preview />
                                                </template>
                                            </Column>
                                            <Column field="quantity" style="min-width:200px" header="采购数量"></Column>
                                            <Column field="colorname" style="min-width:80px" header="颜色"></Column>
                                            <Column field="brand" style="min-width:200px" header="品牌"></Column>
                                            <Column field="size" style="min-width:200px" header="规格尺寸"></Column>
                                            <Column field="inspection" style="min-width:300px" header="是否验货">
                                                <template #body="slotProps">
                                                    {{formatData(slotProps.data['inspection'])}}
                                                </template>
                                            </Column>
                                        </DataTable>
                                    </TabPanel>
                                </TabView>
                            </OverlayPanel>
                        </template>
                    </Column>
                    <Column selectionMode="multiple" frozen bodyStyle="width:45px;" headerStyle="width:45px;"></Column>
                    <Column v-for="col of listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink" #body="slotProps">
                            <Button :label="slotProps.data.purchaseno" @click="openPurchase(slotProps.data,false)"
                                class="p-button-link" />
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                        <template #filterfooter="{filterCallback}">
                            <Button type="button" label="保存查询方案" @click="filterCallback();saveCustomFilter();"
                                class="p-button-outlined p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :childExpandePageConfig="childExpandeConfig"
                :saveBeforeMethod="saveBefore" :CellEditCompleteMethod="cellEditComplete"
                :childNewDataConfig="childNewDataConfig" :initDetailMethod="initDetail"
                :loadDataAfterMethod="loadDataAfter" :childTableFooter="childTableFooter"
                :mainelementCss="mainelementCss" :closeButLoadingMethod="closeButLoading" :currentRow="currentRow"
                :pageJsonConfig="pageJsonConfig" :freshListMethod="freshList" :closeParentMehod="closeMaximizable"
                :isReadOnly="readOnly" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button :label="currentState" icon="pi pi-sitemap" class="p-button-raised p-button-text mr-2"
                    @click="openLeftPosition" />
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button v-if="!readOnly" label="提交" icon="pi pi-check" @click="submitData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="保存查询方案" v-model:visible="displayQueryPlan" :style="{width: '25vw'}" :modal="true">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText type="text" id="queryPlanName" v-model="queryPlanObj.name" />
                        <label for="queryPlanName">查询方案名称</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="取消" icon="pi pi-power-off" :loading="butLoading" @click="closeQueryPlan"
                class="p-button-text" />
            <Button label="保存" icon="pi pi-check" :loading="butLoading" @click="saveQueryPlan" autofocus />
        </template>
    </Dialog>
    <Dialog header="流程信息" v-model:visible="displayPosition" :style="{width: '50vw'}" :position="position"
        :maximizable="true" :modal="true">
        <FlowHistory ref="flowhistory" :flowHistoryData="flowhistoryJson" :title="recordsubject" />
        <template #footer>
            <Button label="关闭" icon="pi pi-power-off" @click="closePosition" autofocus />
        </template>
    </Dialog>
    <Dialog header="验货" v-model:visible="displayInspection" :style="{width: '70vw'}" :modal="true">
        <div class="grid p-fluid mt-3">
            <div class="field col-12 md:col-12">
                <DataTable :value="inspectiondata" :metaKeySelection="false" dataKey="id" :scrollable="false"
                    responsiveLayout="scroll" :paginator="false" v-model:expandedRows="expandedRows" :lazy="false">
                    <Column frozen bodyStyle="width:45px;" headerStyle="width:45px;">
                        <template #body="slotProps">
                            <button @click="expandertoggle2($event,slotProps.data)" aria-haspopup="true"
                                :aria-controls="slotProps.data['id']" class="p-row-toggler p-link" type="button"><span
                                    class="p-row-toggler-icon pi pi-chevron-right"></span><span
                                    class="p-ink"></span></button>
                            <Dialog v-model:visible="slotProps.data['dialogvisible']" modal :style="{ width: '50vw' }">
                                <TabView>
                                    <TabPanel header="验货文件">
                                        <Toolbar class="mb-2">
                                            <template #start>
                                                <Button label="新建" icon="pi pi-plus" class="p-button-success mr-2"
                                                    :loading="butLoading"
                                                    @click="createInspectionFiles(slotProps.data)" />
                                                <Button label="移除" icon="pi pi-trash" class="p-button-danger mr-2"
                                                    :loading="butLoading"
                                                    @click="removeInspectionFiles(slotProps.data)" />
                                            </template>
                                        </Toolbar>
                                        <DataTable :value="expandeinspectiondata['inspectionfile']"
                                            v-model:selection="selectedInspectionFileRow" dataKey="id"
                                            :metaKeySelection="false" scrollHeight="400px" selectionMode="multiple"
                                            scrollDirection="both" editMode="cell"
                                            @row-edit-save="inspectionfileonRowEditSave($event,slotProps.data.rows)"
                                            @cell-edit-complete="inspectionfilecellEditComplete($event,slotProps.data.rows)"
                                            class="editable-cells-table" responsiveLayout="scroll">
                                            <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
                                            <Column field="filename" header="文件">
                                                <template #editor="{ data }">
                                                    <UploadInput v-model:filePatch="data['filepatch']"
                                                        v-model:fileName="data['filename']" fileType="" />
                                                </template>
                                                <template #body="slotProps">
                                                    <a :href="getFilePatch(slotProps.data.filepatch)"
                                                        target="_blank">{{slotProps.data.filename}}</a>
                                                </template>
                                            </Column>
                                        </DataTable>
                                    </TabPanel>
                                </TabView>
                            </Dialog>
                        </template>
                    </Column>
                    <Column field="purchaseno" header="采购单">
                    </Column>
                    <Column field="mpn" header="MPN"></Column>
                    <Column field="sku" header="平台SKU">
                    </Column>
                    <Column field="waitquantity" header="剩余数量"></Column>
                    <Column field="passquantity" header="本次通过数量">
                        <template #body="slotProps">
                            <InputNumber v-model="slotProps.data.passquantity" />
                        </template>
                    </Column>
                    <Column field="nopassquantity" header="本次未通过数量">
                        <template #body="slotProps">
                            <InputNumber v-model="slotProps.data.nopassquantity" />
                        </template>
                    </Column>
                    <Column field="inspectiontime" header="验货时间">
                        <template #body="slotProps">
                            <Calendar :showTime="false" v-model="slotProps.data.inspectiontime" hourFormat="24"
                                dateFormat="yy-mm-dd" />
                        </template>
                    </Column>
                    <Column field="remark" header="验货说明">
                        <template #body="slotProps">
                            <InputText v-model="slotProps.data.remark" />
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeInspection" />
                <Button label="验货" icon="pi pi-check" @click="submitInspection" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="验货" v-model:visible="displayInspection2" :style="{width: '70vw'}" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage2" :childExpandePageConfig="childExpandeConfig2"
                :childNewDataConfig="childNewDataConfig2" :childDelDataConfig="childDelDataConfig2"
                :loadDataAfterMethod="loadDataAfter2" :currentRow="currentRow2" :pageJsonConfig="pageJsonConfig2"
                :openChildDialogMethod="openinspectionfileDialog" :closeButLoadingMethod="closeButLoading"
                :freshListMethod="freshList" :closeParentMehod="closeInspection2" :isReadOnly="false" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button :label="currentState" icon="pi pi-sitemap" class="p-button-raised p-button-text mr-2"
                    @click="openLeftPosition" />
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeInspection2" />
                <Button label="提交" icon="pi pi-check" @click="submitInspection2" :loading="butLoading" autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="验货文件" v-model:visible="displayInspectionFile" :style="{width: '40vw'}" :modal="true">
        <Toolbar class="mb-2">
            <template #start>
                <Button label="新建" icon="pi pi-plus" class="p-button-success mr-2" :loading="butLoading"
                    @click="createInspectionFiles2()" />
                <Button label="移除" icon="pi pi-trash" class="p-button-danger mr-2" :loading="butLoading"
                    @click="removeInspectionFiles2()" />
            </template>
        </Toolbar>
        <DataTable :value="inspectionfiledata['inspectionfile']" v-model:selection="selectedInspectionFileRow"
            dataKey="id" :metaKeySelection="false" scrollHeight="300px" selectionMode="multiple" scrollDirection="both"
            editMode="cell" @row-edit-save="inspectionfileonRowEditSave2($event,slotProps.data.rows)"
            @cell-edit-complete="inspectionfilecellEditComplete2($event)" class="editable-cells-table"
            responsiveLayout="scroll">
            <Column selectionMode="multiple" headerStyle="width: 3em"></Column>
            <Column field="filename" header="文件">
                <template #editor="{ data }">
                    <UploadInput v-model:filePatch="data['filepatch']" v-model:fileName="data['filename']"
                        fileType="" />
                </template>
                <template #body="slotProps">
                    <a :href="getFilePatch(slotProps.data.filepatch)" target="_blank">{{slotProps.data.filename}}</a>
                </template>
            </Column>
        </DataTable>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="确认" icon="pi pi-power-off" class="ml-auto" @click="closeInspectionFile" />
            </div>
        </template>
    </Dialog>
    <Dialog header="验货" v-model:visible="showWait" :style="{width: '30vw'}" :modal="true">
        <h5>数据处理中.....</h5>
    </Dialog>
</template>
<style type="text/css">
    .oneline {
        width: 200px;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
<script>
    import {
        ref,
    } from 'vue';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import {
        FilterMatchMode,
        FilterOperator
    } from 'primevue/api';
    import ConfigService from '../../../service/ConfigService';
    import Image from 'primevue/image';
    import CommonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import FlowHistory from '../../../components/FlowHistory.vue';
    import NewUniversalPage from '../../../components/NewUniversalPage.vue';
    import UploadInput from '../../../components/UploadInput.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '761';
            const listcolumns = ref();
            return {
                confirm,
                currentMenuId,
                listcolumns
            };
        },
        data() {
            return {
                currentPage: 1,
                newId: 2,
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                expandedata: {
                    "products": []
                },
                expandedatabak: {},
                expandeinspectiondata: {
                    "inspectionfile": []
                },
                inspectionfiledata: {
                    "inspectionfile": []
                },
                displayInspectionFile: false,
                butLoading: false,
                recordsubject: '',
                readOnly: true,
                displayMaximizable: false,
                displayQueryPlan: false,
                displayPosition: false,
                displayInspection: false,
                displayInspection2: false,
                showWait: false,
                position: 'bottomleft',
                currentState: '流程信息',
                filters1: {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                currentRow: {
                    bindpage: 'purchase',
                    id: '-1',
                    workflowid: '',
                    flowguid: 'Purchase',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                flowId: '',
                startStateId: '',
                currentRow2: {
                    bindpage: 'inspectionmain',
                    id: '-1',
                    workflowid: '',
                    flowguid: 'Inspection',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                pageJsonConfig2: {},
                pageJsonConfig: {},
                currencyDict: [],
                flowhistoryJson: null,
                uploadurl: '',
                expandedRows: ref(),
                selectedRow: ref(),
                selectedProductRow: ref(),
                selectedInspectionFileRow: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                totalRecords: -1,
                first: 0,
                pagesize: 50,
                listTitle: '待验货列表',
                selectedKey: {
                    761: true
                },
                expandedKey: null,
                childExpandeConfig: {},
                childExpandeConfig2: {},
                purchases: [],
                inspectiondata: [],
                loading: true,
                childloading: false,
                nodes: [],
                items: [{
                    label: '待验货操作',
                    icon: 'pi pi-fw pi-users',
                    items: []
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
                childNewDataConfig: {
                    products: false,
                    files: true,
                    contractterms: true,
                },
                childNewDataConfig2: {
                    childs: false,
                },
                childDelDataConfig2: {
                    childs: true,
                },
                childTableFooter: {
                    products: {
                        mpn: '合计',
                        quantity: 0,
                        allprice: 0,
                        grossweight: 0,
                        netweight: 0
                    }
                },
                mainelementCss: {
                    deliverytimeexplain: 'field col-12 md:col-4',
                    paymenttype: 'field col-12 md:col-4',
                    shippingdate: 'field col-12 md:col-4'
                },
                dropOtherConfig: {
                    currency: 'exchangerate'
                },
            }
        },
        configService: null,
        created() {
            this.pagesize = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.init();
            this.loadFlowInfo();
        },
        methods: {
            init() {
                this.configService.getpurchase(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.childloading = false;
                    this.showWait = false;
                    this.expandedatabak = {};
                    this.listcolumns = data.list;
                    this.pageJsonConfig = data.page;
                    this.childExpandeConfig = data.page['region_3']['left']['element'][0][
                        'childExpandeConfig2'
                    ];
                    this.uploadurl = '../calf/plat/PlatUpload?appkey=' + sessionStorage.getItem("appkey");
                    this.initFilters1();
                    this.loadUserAuth();
                    this.loadMenuTree();
                    CommonJs.loadDictFromCache('Currency', 'dictionaryCurrency', this.currencyDict, this
                        .setloadDictFromCache);
                    this.inspectiondata = [];
                    this.sHeight = (window.innerHeight - 280) + "px";
                    this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
                    this.minHeight = "height:" + (window.innerHeight - 132) + "px";
                    console.log(FilterOperator);
                    console.log(JSON.stringify(this.listcolumns));
                });
                this.configService.getinspectionmain(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.pageJsonConfig2 = data.page;
                    this.childExpandeConfig2 = data.page['region_3']['left']['element'][0][
                        'childExpandeConfig'
                    ];
                });
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize, this.totalRecords);
            },
            setloadDictFromCache(v_currencyDict) {
                this.currencyDict = v_currencyDict;
            },
            getNewId() {
                var id = -1 * this.newId;
                this.newId = this.newId + 1;
                return id;
            },
            initFilters1() {
                CommonJs.initFilters(this.filters1, this.listcolumns, this.setinitFilters);
            },
            setinitFilters(v_filters1) {
                this.filters1 = v_filters1;
            },
            clearFilter1() {
                this.initFilters1();
                this.loadData(1);
            },
            loadUserAuth() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: '791',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usermoduleauthlist(where:" + JSON.stringify(listwhere) +
                    "){appid own authtype}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        var usermoduleauth = jsonData.data.usermoduleauthlist;
                        for (var i = 0; i < usermoduleauth.length; i++) {
                            if (usermoduleauth[i].appid == 'CrmPurchaseInspection' && usermoduleauth[i].own) {
                                this.items[0]['items'].push({
                                    separator: true
                                }, {
                                    label: '采购验货',
                                    icon: 'pi pi-fw pi-user',
                                    command: () => {
                                        this.purchaseInspection2();
                                    }
                                });
                            }
                        }
                    }
                });
            },
            setlistTitleFun(val) {
                this.listTitle = val;
            },
            loadMenuTree() {
                CommonJs.loadMenuTree('791', this.currentMenuId, this.$route, this
                    .loadData, this.setlistTitleFun, this.setloadMenuTree);
            },
            setloadMenuTree(v_nodes, v_selectedKey, v_expandedKey, v_queryPlanObj, v_filters) {
                this.nodes = v_nodes;
                this.selectedKey = v_selectedKey;
                this.expandedKey = v_expandedKey;
                this.queryPlanObj = v_queryPlanObj;
                this.filters = v_filters;
            },
            onNodeSelect(node) {
                console.log(node);
                CommonJs.onNodeSelect(node, this.selectedKey, this.currentMenuId, '待验货列表', this.loadData, this
                    .setlistTitleFun, this.setsaveQueryPlan, this.setinitFilters, this.clearFilter1);
            },
            saveCustomFilter() {
                if (parseInt(this.queryPlanObj.id) >= 0) {
                    this.saveQueryPlan();
                } else {
                    this.displayQueryPlan = true;
                }
            },
            closeQueryPlan() {
                this.closeQueryPlanFun(false);
            },
            butloadinngFun(val) {
                this.butLoading = val;
            },
            closeQueryPlanFun(val) {
                this.displayQueryPlan = val;
            },
            saveQueryPlan() {
                CommonJs.saveQueryPlan(this.queryPlanObj, this.nodes, MessageTip, this.butloadinngFun, this
                    .closeQueryPlanFun, this.setsaveQueryPlan);
            },
            setsaveQueryPlan(v_queryPlanObj, v_nodes) {
                this.queryPlanObj = v_queryPlanObj;
                if (v_nodes)
                    this.nodes = v_nodes;
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            listPage(event) {
                var page = event.page;
                this.pagesize = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize, this);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortfield && sortfield != '') {
                    if (sortorder == '-1') {
                        sortorder = "desc";
                    } else {
                        sortorder = "asc";
                    }
                } else {
                    sortfield = "";
                    sortorder = "";
                }
                this.loadData(page + 1, sortfield, sortorder);
            },
            onSort(event) {
                console.log('onSort...');
                console.log(event);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortorder == '-1') {
                    sortorder = "desc";
                } else {
                    sortorder = "asc";
                }
                this.loadData(1, sortfield, sortorder);
            },
            loadData(v_page, v_sortfield, v_sortorder) {
                this.currentPage = v_page;
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: [{
                        name: 'waitinspectionbox',
                        value: '',
                        operation: '='
                    }]
                };
                if (v_sortfield && v_sortorder) {
                    listwhere['sortfield'] = v_sortfield;
                    listwhere['sortorder'] = v_sortorder;
                }
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'purchaseno,factoryname',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                //{id purchaseno factoryname linkmanname signingdate currencyname exchangerate owner ourcompany deliverydate paymenttype shippingdate approvestatusname approvestatus flowid workflowid boxid stateid currentstatename products {id purchaseno parentid mpn sku productname url quantity packingsize colorname brand size inspection productid craft {id parentid cname craftcontent} inspectiondata {id parentid passquantity nopassquantity} } }}
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{purchaselist(where:" + JSON.stringify(listwhere) +
                    "){id purchaseno factoryname linkmanname signingdate currencyname exchangerate owner ourcompany deliverydate paymenttype shippingdate approvestatusname approvestatus flowid workflowid boxid stateid currentstatename }}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.purchases = jsonData.data.purchaselist;
                        this.totalRecords = jsonData.page.allrecord;
                        this.first = this.pagesize * (jsonData.page.pageindex - 1);
                    }
                });
            },
            openPurchase(v_data) {
                this.readOnly = true;
                this.currentState = '流程信息';
                this.recordsubject = '采购单' + v_data.purchaseno;
                this.childTableFooter = {
                    products: {
                        mpn: '合计',
                        quantity: 0,
                        allprice: 0,
                        grossweight: 0,
                        netweight: 0
                    }
                };
                this.currentRow = {
                    isCopy: false,
                    bindpage: 'purchase',
                    id: v_data.id,
                    workflowid: v_data.workflowid,
                    flowguid: 'Purchase',
                    boxid: v_data.boxid,
                    stateid: v_data.stateid,
                    flowid: v_data.flowid,
                };
                this.displayMaximizable = true;
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            freshList() {
                this.butLoading = false;
                this.loadData(this.currentPage);
            },
            saveBefore(v_data) {
                console.log('saveBefore:');
                console.log(v_data);
                v_data['approvestatus'] = '0';
                v_data['appresult'] = {};
                v_data['shyj'] = {};
                if (v_data['products']) {
                    for (var i = 0; i < v_data['products'].length; i++) {
                        v_data['products'][i]['inspection'] = '0';
                        v_data['products'][i]['inspectionexplain'] = '';
                        v_data['products'][i]['inspectionfile'] = [];
                    }
                }
                return v_data;
            },
            submitData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo();
            },
            getFilePatch(v_patch) {
                if (v_patch == '') {
                    return '/calf/plat/file/attachment/10/noimg.jpg?appkey=' + sessionStorage.getItem("appkey");
                } else {
                    return '/calf/plat/file/' + v_patch + '?appkey=' + sessionStorage.getItem("appkey");
                }
            },
            formatData(v_value) {
                if (v_value == '1') {
                    return '是';
                } else {
                    return '否';
                }
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            openLeftPosition() {
                console.log('recordsubject:' + this.recordsubject);
                this.flowhistoryJson = this.$refs.universalpage.flowhistory;
                this.displayPosition = true;
            },
            closePosition() {
                this.displayPosition = false;
            },
            purchaseInspection() {
                if ((!this.selectedRow || this.selectedRow.length == 0) && (!this.selectedProductRow || this
                        .selectedProductRow.length == 0)) {
                    MessageTip.warnmsg('请选择要验货采购单');
                    return;
                }
                this.inspectiondata = [];
                this.selectedInspectionFileRow = [];
                var waitquantity = 0;
                if (this.selectedRow) {
                    for (var k1 = 0; k1 < this.selectedRow.length; k1++) {
                        for (var k2 = 0; k2 < this.selectedRow[k1].products.length; k2++) {
                            waitquantity = 0;
                            if (!isNaN(Number(this.selectedRow[k1].products[k2]['quantity']))) {
                                waitquantity = Number(this.selectedRow[k1].products[k2]['quantity']);
                            }
                            for (var k3 = 0; k3 < this.selectedRow[k1].products[k2]['inspectiondata'].length; k3++) {
                                if (!isNaN(Number(this.selectedRow[k1].products[k2]['inspectiondata'][k3][
                                        'passquantity'
                                    ]))) {
                                    var tempnum1 = Number(this.selectedRow[k1].products[k2]['inspectiondata'][k3][
                                        'passquantity'
                                    ]);
                                    waitquantity = waitquantity - tempnum1;
                                }
                                if (!isNaN(Number(this.selectedRow[k1].products[k2]['inspectiondata'][k3][
                                        'nopassquantity'
                                    ]))) {
                                    var tempnum2 = Number(this.selectedRow[k1].products[k2]['inspectiondata'][k3][
                                        'nopassquantity'
                                    ]);
                                    waitquantity = waitquantity - tempnum2;
                                }
                            }
                            if (waitquantity > 0) {
                                this.inspectiondata.push({
                                    purchaseno: this.selectedRow[k1]['purchaseno'],
                                    mpn: this.selectedRow[k1].products[k2]['mpn'],
                                    sku: this.selectedRow[k1].products[k2]['sku'],
                                    packingsize: this.selectedRow[k1].products[k2]['packingsize'],
                                    colorname: this.selectedRow[k1].products[k2]['colorname'],
                                    waitquantity: waitquantity,
                                    currentquantity: waitquantity,
                                    passquantity: 0,
                                    nopassquantity: 0,
                                    inspectiontime: '',
                                    remark: '',
                                    parentid: this.selectedRow[k1].products[k2]['id'],
                                    purchaseid: this.selectedRow[k1]['id'],
                                    inspectionfile: [],
                                });
                            }
                        }
                    }
                }
                if (this.selectedProductRow) {
                    for (var i1 = 0; i1 < this.selectedProductRow.length; i1++) {
                        waitquantity = 0;
                        if (!isNaN(Number(this.selectedProductRow[i1]['quantity']))) {
                            waitquantity = Number(this.selectedProductRow[i1]['quantity']);
                        }
                        for (var i3 = 0; i3 < this.selectedProductRow[i1]['inspectiondata'].length; i3++) {
                            if (!isNaN(Number(this.selectedProductRow[i1]['inspectiondata'][i3][
                                    'passquantity'
                                ]))) {
                                var tempnum3 = Number(this.selectedProductRow[i1]['inspectiondata'][i3][
                                    'passquantity'
                                ]);
                                waitquantity = waitquantity - tempnum3;
                            }
                            if (!isNaN(Number(this.selectedProductRow[i1]['inspectiondata'][i3][
                                    'nopassquantity'
                                ]))) {
                                var tempnum4 = Number(this.selectedProductRow[i1]['inspectiondata'][i3][
                                    'nopassquantity'
                                ]);
                                waitquantity = waitquantity - tempnum4;
                            }
                        }
                        var noexist = false;
                        for (var i2 = 0; i2 < this.inspectiondata.length; i2++) {
                            if (this.inspectiondata[i2]['parentid'] == this.selectedProductRow[i1]['id']) {
                                noexist = true;
                                break;
                            }
                        }
                        if (!noexist && waitquantity > 0) {
                            this.inspectiondata.push({
                                purchaseno: this.selectedProductRow[i1]['purchaseno'],
                                mpn: this.selectedProductRow[i1]['mpn'],
                                sku: this.selectedProductRow[i1]['sku'],
                                packingsize: this.selectedProductRow[i1]['packingsize'],
                                colorname: this.selectedProductRow[i1]['colorname'],
                                waitquantity: waitquantity,
                                currentquantity: waitquantity,
                                passquantity: 0,
                                nopassquantity: 0,
                                inspectiontime: '',
                                remark: '',
                                parentid: this.selectedProductRow[i1]['id'],
                                purchaseid: this.selectedProductRow[i1]['parentid'],
                                inspectionfile: [],
                            });
                        }
                    }
                }
                this.displayInspection = true;
            },
            purchaseInspection2() {
                if ((!this.selectedRow || this.selectedRow.length == 0) && (!this.selectedProductRow || this
                        .selectedProductRow.length == 0)) {
                    MessageTip.warnmsg('请选择要验货采购单');
                    return;
                }
                if (this.selectedRow && this.selectedRow.length > 0) {
                    this.showWait = true;
                    this.reloadproduct();
                } else {
                    this.currentState = '流程信息';
                    this.currentRow2 = {
                        isCopy: false,
                        bindpage: 'inspectionmain',
                        id: '-1',
                        workflowid: '',
                        flowguid: 'Inspection',
                        boxid: '',
                        stateid: this.startStateId,
                        flowid: this.flowId,
                    };
                    this.displayInspection2 = true;
                }
            },
            reloadproduct() {
                var idstr = '';
                for (var i = 0; i < this.selectedRow.length; i++) {
                    if (idstr == '') {
                        idstr = this.selectedRow[i]['id'];
                    } else {
                        idstr = idstr + ',' + this.selectedRow[i]['id'];
                    }
                }
                var listwhere = {
                    pageindex: 1,
                    pagesize: this.selectedRow.length + 10,
                    conditions: [{
                        name: 'id',
                        value: idstr,
                        operation: 'in'
                    }],
                    child: [{
                        objname: 'products',
                        conditions: [{
                            name: 'inspection',
                            value: '0',
                            operation: '='
                        }],
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{purchaselist(where:" + JSON.stringify(listwhere) +
                    "){id purchaseno factoryname linkmanname signingdate currencyname exchangerate owner ourcompany deliverydate paymenttype shippingdate approvestatusname approvestatus flowid workflowid boxid stateid currentstatename products {id purchaseno parentid mpn sku productname url quantity packingsize colorname brand size inspection productid craft {id parentid cname craftcontent} inspectiondata {id parentid passquantity nopassquantity} } }}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.selectedRow = jsonData.data.purchaselist;

                        this.currentState = '流程信息';
                        this.currentRow2 = {
                            isCopy: false,
                            bindpage: 'inspectionmain',
                            id: '-1',
                            workflowid: '',
                            flowguid: 'Inspection',
                            boxid: '',
                            stateid: this.startStateId,
                            flowid: this.flowId,
                        };
                        this.showWait = false;
                        this.displayInspection2 = true;
                    }
                });
            },
            submitInspection() {
                if (this.inspectiondata.length == 0) {
                    MessageTip.warnmsg('没有要验货的产品');
                    return;
                }
                for (var i = 0; i < this.inspectiondata.length; i++) {
                    var waitquantity = 0;
                    if (!isNaN(Number(this.inspectiondata[i]['waitquantity']))) {
                        waitquantity = Number(this.inspectiondata[i]['waitquantity']);
                    }
                    var passquantity = 0;
                    if (!isNaN(Number(this.inspectiondata[i]['passquantity']))) {
                        passquantity = Number(this.inspectiondata[i]['passquantity']);
                    }
                    var nopassquantity = 0;
                    if (!isNaN(Number(this.inspectiondata[i]['nopassquantity']))) {
                        nopassquantity = Number(this.inspectiondata[i]['nopassquantity']);
                    }
                    if (passquantity + nopassquantity == 0) {
                        MessageTip.warnmsg('请填写验货通过数量和未通过数量');
                        return;
                    }
                    if (waitquantity < passquantity + nopassquantity) {
                        MessageTip.warnmsg('验货通过数量和未通过数量之和不能大于剩余数量');
                        return;
                    }
                    if (this.inspectiondata[i]['inspectiontime'] == '') {
                        MessageTip.warnmsg('请填写验货时间');
                        return;
                    } else {
                        this.inspectiondata[i]['inspectiontime'] = CommonJs.formatDate('DateString', this
                            .inspectiondata[i]['inspectiontime']);
                    }
                }
                this.butLoading = true;
                console.log(this.inspectiondata);
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "mutation{purchaseinspectionlist(o:" + JSON.stringify(this.inspectiondata) +
                    "){id}}"
                ).then(res => {
                    this.butLoading = false;
                    if (res.errcode == "0") {
                        MessageTip.successmsg('验货成功');
                        this.selectedRow = ref();
                        this.selectedProductRow = ref();
                        this.inspectiondata = [];
                        this.selectedInspectionFileRow = [];
                        this.freshList();
                        this.closeInspection();
                    } else {
                        MessageTip.warnmsg('验货失败');
                    }
                });
            },
            closeInspection() {
                this.displayInspection = false;
            },
            closeInspection2() {
                this.displayInspection2 = false;
            },
            removeInspectionFiles(v_data) {
                console.log(this.selectedInspectionFileRow);
                if (!this.selectedInspectionFileRow || this.selectedInspectionFileRow.length == 0) {
                    MessageTip.warnmsg('请选择要移除的验货文件');
                    return;
                }
                for (var i2 = 0; i2 < this.selectedInspectionFileRow.length; i2++) {
                    for (var i = 0; i < v_data['inspectionfile'].length; i++) {
                        if (v_data['inspectionfile'][i]['id'] == this.selectedInspectionFileRow[i2]['id']) {
                            v_data['inspectionfile'].splice(i, 1);
                            break;
                        }
                    }
                }
                this.selectedInspectionFileRow = [];
            },
            createInspectionFiles(v_data) {
                v_data['inspectionfile'].push({
                    id: this.getNewId(),
                    filename: '',
                    filepatch: '',
                    parentid: '',
                    purchaseid: v_data['purchaseid']
                });
            },
            createInspectionFiles2() {
                this.inspectionfiledata['inspectionfile'].push({
                    id: this.getNewId(),
                    filename: '',
                    filepatch: '',
                    parentid: '',
                    purchaseid: this.inspectionfiledata['purchaseid']
                });
            },
            removeInspectionFiles2() {
                console.log(this.selectedInspectionFileRow);
                if (!this.selectedInspectionFileRow || this.selectedInspectionFileRow.length == 0) {
                    MessageTip.warnmsg('请选择要移除的验货文件');
                    return;
                }
                for (var i2 = 0; i2 < this.selectedInspectionFileRow.length; i2++) {
                    for (var i = 0; i < this.inspectionfiledata['inspectionfile'].length; i++) {
                        if (this.inspectionfiledata['inspectionfile'][i]['id'] == this.selectedInspectionFileRow[i2][
                                'id'
                            ]) {
                            this.inspectionfiledata['inspectionfile'].splice(i, 1);
                            break;
                        }
                    }
                }
                this.selectedInspectionFileRow = [];
            },
            cellEditComplete(tablename, field, data, rowdata, childTableFooterObj) {
                console.log('cellEditCompleteMethod..');
                console.log(data);
                if (tablename == 'products' && field == 'quantity' || field == 'unitprice') {
                    var quantitynum = Number(rowdata['quantity']);
                    var unitpricenum = Number(rowdata['unitprice']);
                    if (!isNaN(quantitynum) && !isNaN(unitpricenum)) {
                        rowdata['allprice'] = quantitynum * unitpricenum;
                    }
                    var summary = 0;
                    for (var i = 0; i < data.length; i++) {
                        var tempnum = Number(data[i]['allprice']);
                        if (!isNaN(tempnum)) {
                            summary = summary + tempnum;
                        }
                    }
                    console.log('cellEditCompleteMethod..' + summary);
                    childTableFooterObj['products']['allprice'] = summary;
                }
            },
            initDetail(v_editorMenu) {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'id',
                        value: 'PurchaseTerm',
                        operation: '=',
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{generaldictsetlist(where:" + JSON.stringify(listwhere) +
                    "){name categories data {rowid name colname colvalue coltype}}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        var retData = jsonData.data.generaldictsetlist;
                        for (var i = 0; i < retData.length; i++) {
                            var rowObj = {
                                name: retData[i]['name'],
                                categories: retData[i]['categories'],
                                rows: [],
                                cols: [],
                            };
                            for (var j = 0; j < retData[i]['data'].length; j++) {
                                var isAdd = true;
                                for (var k1 = 0; k1 < rowObj.cols.length; k1++) {
                                    if (rowObj.cols[k1]['name'] == retData[i]['data'][j]['colname']) {
                                        isAdd = false;
                                        break;
                                    }
                                }
                                if (isAdd) {
                                    rowObj.cols.push({
                                        label: retData[i]['data'][j]['name'],
                                        name: retData[i]['data'][j]['colname'],
                                        type: retData[i]['data'][j]['coltype']
                                    });
                                }
                            }
                            for (var j1 = 0; j1 < retData[i]['data'].length; j1++) {
                                var rowAdd = true;
                                for (var k2 = 0; k2 < rowObj.rows.length; k2++) {
                                    if (retData[i]['data'][j1]['rowid'] == rowObj.rows[k2]['id']) {
                                        rowAdd = false;
                                        break;
                                    }
                                }
                                if (rowAdd) {
                                    var rowData = {
                                        id: retData[i]['data'][j1]['rowid']
                                    };
                                    for (var k3 = 0; k3 < retData[i]['data'].length; k3++) {
                                        if (retData[i]['data'][j1]['rowid'] == retData[i]['data'][k3][
                                                'rowid'
                                            ]) {
                                            rowData[retData[i]['data'][k3]['colname']] = retData[i]['data'][k3][
                                                'colvalue'
                                            ];
                                        }
                                    }
                                    rowObj.rows.push(rowData);
                                }
                            }
                            for (var k = 0; k < rowObj.rows.length; k++) {
                                v_editorMenu.push({
                                    label: rowObj.rows[k]['SName'],
                                    icon: 'pi pi-plus',
                                    colname: 'termcontent',
                                    content: rowObj.rows[k]['AdditionalInfo'],
                                    command: ($event) => {
                                        this.$refs.universalpage.setEditorValue($event);
                                    }
                                });
                            }
                        }
                    }
                });
            },
            expandertoggle(event, data) {
                console.log(data);
                if (this.expandedatabak[data['id']]) {
                    this.expandedata = this.expandedatabak[data['id']];
                    this.childloading = false;
                } else {
                    this.expandedata = {
                        products: []
                    };
                    this.childloading = true;
                    var listwhere = {
                        pageindex: 1,
                        pagesize: 10,
                        conditions: [{
                            name: 'id',
                            value: data['id'] + '',
                            operation: '='
                        }],
                        child: [{
                            objname: 'products',
                            conditions: [{
                                name: 'inspection',
                                value: '0',
                                operation: '='
                            }],
                        }]
                    };
                    requests.graphql_requests.post(
                        '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                        "{purchaselist(where:" + JSON.stringify(listwhere) +
                        "){id purchaseno factoryname linkmanname signingdate currencyname exchangerate owner ourcompany deliverydate paymenttype shippingdate approvestatusname approvestatus flowid workflowid boxid stateid currentstatename products {id purchaseno parentid mpn sku productname url quantity packingsize colorname brand size inspection productid craft {id parentid cname craftcontent} inspectiondata {id parentid passquantity nopassquantity} } }}"
                    ).then(res => {
                        this.loading = false;
                        if (res.errcode == "0") {
                            var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                            this.childloading = false;
                            if (jsonData.data.purchaselist.length > 0) {
                                this.expandedata = jsonData.data.purchaselist[0];
                                this.expandedatabak[jsonData.data.purchaselist[0]['id']] = jsonData.data
                                    .purchaselist[0];
                            }
                        }
                    });
                }
                this.$refs.op.toggle(event);
            },
            expandertoggle2(event, data) {
                console.log(event)
                console.log(data);
                this.expandeinspectiondata = data;
                data['dialogvisible'] = true;
            },
            loadDataAfter(v_mainelements, v_childelements, v_originalData, v_noshowelements,
                v_childExpandeConfig) {
                console.log(v_mainelements);
                console.log(v_childelements);
                console.log(v_originalData);
                console.log(v_noshowelements);
                for (var k = 0; k < v_mainelements.length; k++) {
                    if (v_mainelements[k]['name'] == 'currency') {
                        this.currencyChange(v_childelements, v_mainelements[k]['val'], this.currencyDict,
                            v_childExpandeConfig);
                    }
                }
            },
            currencyChange(v_childelements, v_val, v_dictsource, v_childExpandeConfig) {
                var ename = '';
                for (var i3 = 0; i3 < v_dictsource.length; i3++) {
                    if (v_dictsource[i3].code == v_val) {
                        ename = v_dictsource[i3].ename;
                        break;
                    }
                }
                var loopcnt = 0;
                var isbreak = false;
                for (var i = 0; i < v_childelements.length; i++) {
                    for (var i2 = 0; i2 < v_childelements[i].columns.length; i2++) {
                        if (v_childelements[i].columns[i2].field == 'unitprice') {
                            v_childelements[i].columns[i2].header = '单价(' + ename + ')';
                            loopcnt++;
                        }
                        if (v_childelements[i].columns[i2].field == 'allprice') {
                            v_childelements[i].columns[i2].header = '总价(' + ename + ')';
                            loopcnt++;
                        }
                        if (loopcnt == 2) {
                            isbreak = true;
                            break;
                        }
                    }
                    if (isbreak) {
                        break;
                    }
                }
                for (var i4 = 0; i4 < v_childExpandeConfig.products.config2[0].columns.length; i4++) {
                    if (v_childExpandeConfig.products.config2[0].columns[i4].field == 'unitprice') {
                        v_childExpandeConfig.products.config2[0].columns[i4].header = '单价(' + ename + ')';
                    }
                    if (v_childExpandeConfig.products.config2[0].columns[i4].field == 'allprice') {
                        v_childExpandeConfig.products.config2[0].columns[i4].header = '总价(' + ename + ')';
                    }
                }
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            inspectionfilecellEditComplete(event, v_alldata) {
                console.log("event:");
                console.log(event);
                console.log("v_alldata:");
                console.log(v_alldata);
                for (var pro in event.newData) {
                    event.data[pro] = event.newData[pro];
                }
            },
            inspectionfileonRowEditSave(event, v_data) {
                console.log(event);
                console.log(v_data);
                let {
                    newData,
                    index
                } = event;
                v_data[index] = newData;
            },
            inspectionfileonRowEditSave2(event, v_data) {
                console.log(event);
                console.log(v_data);
                let {
                    newData,
                    index
                } = event;
                v_data[index] = newData;
            },
            inspectionfilecellEditComplete2(event) {
                console.log("event:");
                console.log(event);
                for (var pro in event.newData) {
                    event.data[pro] = event.newData[pro];
                }
            },
            dialogClose() {
                this.butLoading = false;
            },
            loadFlowInfo() {
                requests.plat_requests.post(
                    '/calf/plat/FlowInfo?appkey=' + sessionStorage.getItem(
                        "appkey"), {
                        FlowGuid: 'Inspection',
                        BoxId: '',
                    }).then(res => {
                    console.log('loadFlowInfo...');
                    console.log(res);
                    if (res.errcode == "0") {
                        var retObj = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log(retObj);
                        this.flowId = retObj.FlowId;
                        this.startStateId = retObj.CurrentStateId;
                    }
                });
            },
            loadDataAfter2(v_mainelements, v_childelements, v_originalData, v_noshowelements) {
                console.log('loadDataAfter2....');
                console.log(v_mainelements);
                console.log(v_childelements);
                console.log(v_originalData);
                console.log(v_noshowelements);
                console.log(this.pageJsonConfig2);
                if (this.currentRow2 && this.currentRow2['id'] == '-1') {
                    var childsObj = [];
                    var waitquantity = 0;
                    var status = '0';
                    if (!this.pageJsonConfig2['openflow']) {
                        status = '1';
                    }
                    if (this.selectedRow) {
                        for (var k1 = 0; k1 < this.selectedRow.length; k1++) {
                            for (var k2 = 0; k2 < this.selectedRow[k1].products.length; k2++) {
                                waitquantity = 0;
                                if (!isNaN(Number(this.selectedRow[k1].products[k2]['quantity']))) {
                                    waitquantity = Number(this.selectedRow[k1].products[k2]['quantity']);
                                }
                                for (var k3 = 0; k3 < this.selectedRow[k1].products[k2]['inspectiondata']
                                    .length; k3++) {
                                    if (!isNaN(Number(this.selectedRow[k1].products[k2]['inspectiondata'][k3][
                                            'passquantity'
                                        ]))) {
                                        var tempnum1 = Number(this.selectedRow[k1].products[k2]['inspectiondata'][k3][
                                            'passquantity'
                                        ]);
                                        waitquantity = waitquantity - tempnum1;
                                    }
                                    if (!isNaN(Number(this.selectedRow[k1].products[k2]['inspectiondata'][k3][
                                            'nopassquantity'
                                        ]))) {
                                        var tempnum2 = Number(this.selectedRow[k1].products[k2]['inspectiondata'][k3][
                                            'nopassquantity'
                                        ]);
                                        waitquantity = waitquantity - tempnum2;
                                    }
                                }
                                if (waitquantity > 0) {
                                    childsObj.push({
                                        id: "-" + this.selectedRow[k1].products[k2]['id'],
                                        purchaseno: this.selectedRow[k1]['purchaseno'],
                                        mpn: this.selectedRow[k1].products[k2]['mpn'],
                                        sku: this.selectedRow[k1].products[k2]['sku'],
                                        packingsize: this.selectedRow[k1].products[k2]['packingsize'],
                                        colorname: this.selectedRow[k1].products[k2]['colorname'],
                                        waitquantity: waitquantity,
                                        currentquantity: waitquantity,
                                        passquantity: 0,
                                        nopassquantity: 0,
                                        inspectiontime: '',
                                        remark: '',
                                        parentid: '',
                                        status: status,
                                        purchaseproductid: this.selectedRow[k1].products[k2]['id'],
                                        purchaseid: this.selectedRow[k1]['id'],
                                        inspectionfile: [],
                                        purchasecraft: this.selectedRow[k1].products[k2]['craft'],
                                    });
                                }
                            }
                        }
                    }
                    if (this.selectedProductRow) {
                        for (var i1 = 0; i1 < this.selectedProductRow.length; i1++) {
                            waitquantity = 0;
                            if (!isNaN(Number(this.selectedProductRow[i1]['quantity']))) {
                                waitquantity = Number(this.selectedProductRow[i1]['quantity']);
                            }
                            for (var i3 = 0; i3 < this.selectedProductRow[i1]['inspectiondata'].length; i3++) {
                                if (!isNaN(Number(this.selectedProductRow[i1]['inspectiondata'][i3][
                                        'passquantity'
                                    ]))) {
                                    var tempnum3 = Number(this.selectedProductRow[i1]['inspectiondata'][i3][
                                        'passquantity'
                                    ]);
                                    waitquantity = waitquantity - tempnum3;
                                }
                                if (!isNaN(Number(this.selectedProductRow[i1]['inspectiondata'][i3][
                                        'nopassquantity'
                                    ]))) {
                                    var tempnum4 = Number(this.selectedProductRow[i1]['inspectiondata'][i3][
                                        'nopassquantity'
                                    ]);
                                    waitquantity = waitquantity - tempnum4;
                                }
                            }
                            var noexist = false;
                            for (var i2 = 0; i2 < childsObj.length; i2++) {
                                if (childsObj[i2]['purchaseproductid'] == this.selectedProductRow[i1]['id']) {
                                    noexist = true;
                                    break;
                                }
                            }
                            if (!noexist && waitquantity > 0) {
                                childsObj.push({
                                    id: "-" + this.selectedProductRow[i1]['id'],
                                    purchaseno: this.selectedProductRow[i1]['purchaseno'],
                                    mpn: this.selectedProductRow[i1]['mpn'],
                                    sku: this.selectedProductRow[i1]['sku'],
                                    packingsize: this.selectedProductRow[i1]['packingsize'],
                                    colorname: this.selectedProductRow[i1]['colorname'],
                                    waitquantity: waitquantity,
                                    currentquantity: waitquantity,
                                    passquantity: 0,
                                    nopassquantity: 0,
                                    inspectiontime: '',
                                    remark: '',
                                    parentid: '',
                                    status: status,
                                    purchaseproductid: this.selectedProductRow[i1]['id'],
                                    purchaseid: this.selectedProductRow[i1]['parentid'],
                                    inspectionfile: [],
                                    purchasecraft: this.selectedProductRow[i1]['craft'],
                                });
                            }
                        }
                    }
                    for (var i5 = 0; i5 < v_childelements.length; i5++) {
                        if (v_childelements[i5]['name'] == 'childs') {
                            v_childelements[i5]['rows'] = childsObj;
                        }
                    }
                }
            },
            submitInspection2() {
                this.butLoading = true;
                this.selectedRow = [];
                this.selectedProductRow = [];
                if (this.pageJsonConfig2['openflow']) {
                    this.$refs.universalpage2.saveInfo();
                } else {
                    this.$refs.universalpage2.saveInfo(true);
                }
            },
            openinspectionfileDialog(v_childdata, v_parentdata) {
                console.log('openinspectionfileDialog...');
                console.log(v_childdata);
                console.log(v_parentdata);
                this.inspectionfiledata = v_parentdata;
                this.displayInspectionFile = true;
            },
            closeInspectionFile() {
                this.inspectionfiledata = {};
                this.displayInspectionFile = false;
            },
        },
        components: {
            NewUniversalPage,
            FlowHistory,
            UploadInput,
            'AppFooter': AppFooter,
            Image,
        }
    }
</script>
<style lang="scss" scoped>
    .product-image {
        width: 50px;
        box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)
    }

    .orders-subtable {
        padding: 1rem;
    }
</style>